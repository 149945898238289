import React, { Component } from 'react';
import Graph from './Graph';

class DogGraph extends Component {

  state = {
    breeds: []
  }

  componentDidMount() {
    fetch('https://api.thedogapi.com/v1/breeds')
    .then(res => res.json())
    .then((data) => {
      this.setState({ breeds: data })
    })
    .catch(console.log)
  }



  render() {
    return (
          <Graph breeds={this.state.breeds} />
    );
  }
}

export default DogGraph;
