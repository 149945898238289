import React from 'react';
import './App.css';
import logotype from './logotype.png'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dogs from './components/Dogs.js';
import DogGraph from './components/DogGraph.js';
import CustomizedDialogs from './components/Modal.js';
import Favorite from './components/Favorite.js';
import Charts from './components/Charts.js';
import Dog from './components/Dog.js';
import IconButton from '@material-ui/core/IconButton';
import Doggopedia from './components/Doggopedia.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#efefef'
  },
  logotype: {
    maxWidth: '120px'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
    color: '#282c34'
  },
  contactButton: {
    marginRight: theme.spacing(1),
    color: '#282c34',
    border: '#efefef 1px solid'
  },
  title: {
    flexGrow: 1,
  }
}));


function App() {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
    <Dogs />
    <DogGraph />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box className={classes.title}>
            <IconButton >
                <img src={logotype} className={classes.logotype} alt="Doggo-pedia" />
            </IconButton>
          </Box>
          <Dog />
          <Charts />
          <Favorite />
          <CustomizedDialogs />
        </Toolbar>
      </AppBar>
      <header className="App-header">


      </header>
    </Box>
  );
}

export default App;
