import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import logotype from '../logotype.png'
import doggopedia from '../Doggopedia.png';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    textAlign: 'center',
    backgroundColor: '#f9db26',
    width: '400px',
    padding:' 2em 2em 1em 2em',
    margin: '0 1em 1em'
  },
  box: {

  },
  divider: {
    height: 28,
    margin: 4,
  },
  logotype: {
      width: '220px',
      marginBottom: '1em'
    },
  titleStyles: {
      textAlign: 'center',
    }

}));

export default function Doggopedia() {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root}>
      <img src={logotype} className={classes.logotype} alt="Doggo-pedia" />
      <Typography gutterBottom className={classes.titleStyles} >
        Doggopedia was built by <a href="https://adamelliott.com/"> Adam Elliott</a>.
      </Typography>
    </Paper>
  );
}
