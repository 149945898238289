import React from 'react';

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  MarkSeries,
  LabelSeries
} from 'react-vis';


import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import "react-vis/dist/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1em',
    marginBottom: '6em'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  card: {
  },
  graph: {
    width:'100vw',
    height:'100%',
  },
  title: {
    fontSize: 14,
  },
  label: {
    marginTop: theme.spacing(2)
  },
  pos: {
    marginBottom: 12,
  },
}));

    const Graph = ({ breeds }) => {
      const classes = useStyles();
      const breedData = [];
      var breedColor = 0;
      breeds.forEach(function(i){
          switch (i.breed_group) {
            case "Hound": breedColor = 1
            break;
            case "Toy": breedColor = 2
            break;
            case "Terrier": breedColor = 3
            break;
            case "Working": breedColor = 4
            break;
            case "Mixed": breedColor = 5
            break;
            case "Sporting": breedColor = 6
            break;
            case "Non-sporting": breedColor = 7
            break;
            default: breedColor = 0
          }

          let dogX = parseInt( i.life_span.split("-")[0].split("–")[0].split("y")[0].replace(/\s/g, '') )
          let dogY = parseInt( i.weight.imperial.split("-")[0].replace(/\s/g, '') )

          if (Number.isInteger(dogX) && Number.isInteger(dogY)) {
            let dogData = {
              y: dogY,
              x: dogX,
              size: parseInt( i.height.imperial.split("-")[0].replace(/\s/g, '') ),
              label: i.name,
              fill: breedColor
            }
            breedData.push(dogData)
          }

      })

      return (
        <Grid id="graph" container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.root}>
            <XYPlot width={window.innerWidth-50} height={window.innerHeight-200} className={classes.graph}>
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis />
              <MarkSeries
                className="mark-series-example"
                strokeWidth={2}
                opacity="0.8"
                sizeRange={[2, 25]}
                color={breedData}
                data={breedData}
              />
              <LabelSeries animation data={breedData} />
            </XYPlot>
        </Grid>
      </Grid>
      )
    };

    export default Graph
