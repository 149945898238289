import React, { Component } from 'react';
import ComplexCard from './ComplexCard';

class Dogs extends Component {

  state = {
    breeds: [],
    photos: []
  }

  componentDidMount() {
    fetch('https://api.thedogapi.com/v1/breeds')
    .then(res => res.json())
    .then((data) => {
      this.setState({ breeds: data })
    })
    .catch(console.log)

    for (var i=0; i<this.state.breeds.length; i++) {
      fetch(`https://api.thedogapi.com/v1/images/search?include_breed=1&breed_id=${i}`)
      .then(res => res.json())
      .then((data) => {
        this.setState({ photos: data[0].url })
      })
      .catch(console.log)
    }



  }



  render() {
    return (
          <ComplexCard breeds={this.state.breeds} photos={this.state.photos} />
    );
  }
}

export default Dogs;
