import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  card: {
    margin: theme.spacing(2),
  },
  title: {
    fontSize: 24,
  },
  label: {
    marginTop: theme.spacing(3),
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  learnMore: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color:'#3d3d3d',
    marginLeft: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundPosition: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',

  },
  like: {
    color: '',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  liked: {
    color: '#ec86b5'
  },
  avatar: {
    backgroundColor: red[500],
  }
}));

const ComplexCard = ({ photos, breeds }) => {
  const classes = useStyles();
  const [expandedId, setExpandedId] = React.useState(-1);

    const handleExpandClick = (i) => {
      setExpandedId(expandedId === i ? -1 : i);
    };


  const likedList = [-1]

  const [likedId, setLikedId] = React.useState(-1);

    const handleLikedClick = (i) => {
      setLikedId(likedId === i ? -1 : i);
    };

    const handleImageClick = (e, i) => {
      console.log(i)
      let dogName = i.replace(/\s/g, '+');
      console.log(dogName)
      e.target.style.backgroundImage = `url("https://source.unsplash.com/800x400/?${dogName},dog")`
    };

  return (
    <Grid container className={classes.root}>
      {breeds.map((breed) => (
        <Grid key={breed.id} data-group={breed.breed_group} item xs={12} sm={12} md={6} lg={3} xl={2}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {breed.name.charAt(0)}
                </Avatar>
              }
              action={
                <IconButton
                  className={clsx(classes.like, {
                    [classes.liked]: likedId === breed.id,
                  })}
                  onClick={() => handleLikedClick(breed.id)}
                  aria-expanded={likedId === breed.id}
                  aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
              }
              title={breed.name}
              subheader={breed.origin}
            />
            <CardMedia
              className={classes.media}
              image={`https://source.unsplash.com/800x400/?dog,${ breed.name.replace(/\s/g, '+') }`}
              title={breed.name}
              onClick={(e) => handleImageClick(e, breed.name)}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                The {breed.name} was bred for {breed.bred_for ? breed.bred_for.toLowerCase() : "unknown reasons"} and grows to be {breed.weight.imperial} lbs. They are known for being {breed.temperament ? breed.temperament.toLowerCase() : "hard to figure out"}.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
            <Typography variant="h6" component="h6" className={classes.learnMore}>
              {breed.breed_group ? (breed.breed_group + " Group") : "GoodBoye Group"}
            </Typography>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expandedId === breed.id,
                      })}
                      onClick={() => handleExpandClick(breed.id)}
                      aria-expanded={expandedId === breed.id}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </CardActions>
                  <Collapse in={expandedId === breed.id} timeout="auto" unmountOnExit>
                    <CardContent>
                    <Typography variant="body2" component="p" className={classes.title}>
                      {breed.name}
                    </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Breed origin:
                      </Typography>
                      <Typography variant="body2" component="p">
                        {breed.origin ? breed.origin : 'unknown'}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Class:
                      </Typography>
                      <Typography variant="body2" component="p">
                        {breed.breed_group ? breed.breed_group : 'Goodboye'}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Bred for:
                      </Typography>
                      <Typography variant="body2" component="p">
                        {breed.bred_for ? breed.bred_for : 'unknown'}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Temperament:
                      </Typography>
                      <Typography variant="body2" component="p">
                          {breed.temperament ? breed.temperament : 'unknown'}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Lifespan:
                      </Typography>
                      <Typography variant="body2" component="p">
                        {breed.life_span ? breed.life_span : 'unknown'}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Weight:
                      </Typography>
                      <Typography variant="body2" component="p">
                        {breed.weight.imperial ? breed.weight.imperial : '?'} lbs. / {breed.weight.metric ? breed.weight.metric : '?'} kg.
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.label}>
                        Height:
                      </Typography>
                      <Typography variant="body2" component="p">
                        {breed.height.imperial ? breed.height.imperial : '?'} in. / {breed.height.metric ? breed.height.metric : '?'} cm.
                      </Typography>
                    </CardContent>
                  </Collapse>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}


    export default ComplexCard
