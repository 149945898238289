import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import logo from '../logo.svg';
import logotype from '../logotype.png'
import doggopedia from '../Doggopedia.png';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
    background: '#f9db26',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  doggopedia: {
    width: '100%',
  },
  title: {
    color: 'rgba(0,0,0,0)',
    fontSize: 10,
    padding: 0,
    margin: 0
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: '#f9db26',
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    background: '#f9db26 !Important',
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {

  const doggopediaStyles = {
    maxWidth: '300px',
  }

  const titleStyles = {
    textAlign: 'center',
    marginBottom: '3em',
  }

  const logoStyles =  {
      maxWidth: '220px',
      marginTop: '0em',
      marginBottom: '1em'
    }

  const labelStyles = {
       marginTop: '3em',
       marginBottom: '.25em',
       fontSize: 10,
       textTransform: 'uppercase',
       fontWeight: 'bold',
  }

  const creditStyles = {
       marginTop: '0em',
       marginBottom: '.25em',
       fontSize: 12,
  }

  const dialogStyles = {
       background: '#f9db26 !Important',
  }

  const boxStyles = {
       background: '#f9db26',
       fontSize: 10,
       padding: 0,
       margin: '0 auto'
  }



  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClickOpen}>
      <img src={logo} className="App-logo" alt="logo" />
    </IconButton>
      <Dialog onClose={handleClose} aria-labelledby="about-doggopedia" open={open} style={dialogStyles}>

        <DialogContent>
          <Box width="100%" styles="boxStyles" display="flex" justifyContent="center">
            <DialogTitle styles="boxStyles" id="about-doggopedia" onClose={handleClose}>
              About Doggo-pedia
            </DialogTitle>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <img src={doggopedia} alt="doggopedia" style={doggopediaStyles} />
            <img src={logotype} style={logoStyles} alt="Doggo-pedia" />
          </Box>
          <Typography gutterBottom style={titleStyles} >
            is a project by <a href="https://adamelliott.com/"> Adam Elliott</a>.
          </Typography>
          <Typography variant="body2" component="p" style={labelStyles}>
            Credits:
          </Typography>
          <Typography variant="body2" component="p" style={creditStyles}>
            • Facts provided by <a href="https://thedogapi.com/">The Dog API</a>.
          </Typography>
          <Typography variant="body2" component="p" style={creditStyles}>
            • Photos provided by <a href="https://unsplash.com/">Unsplash</a>.
          </Typography>
          <Typography variant="body2" component="p" style={creditStyles}>
            • Built using <a href="https://reactjs.org/">React</a>,  <a href="https://material-ui.com/">Material-UI</a>, and <a href="https://github.com/uber/react-vis">react-vis</a>.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
